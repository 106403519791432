/*-- Variables --*/
:root {
  /* Themes colors */
  --color-primary: #FFDC00;
  /* Slogan color */
  --color-tertiary: #D7D5D5;
  /* Rust color */
  --color-secondary: #B7410E;
  /* Black */
  --color-con: #FFDC00;
  /* Con color */
  --color-black: #000000;
  /* Black - You can adjust or change to another color if needed */


  // Typo Color
  --color-heading: #ffffff;
  --color-body: #acacac;

  --color-dark: #060606;
  --color-darker: rgba(6, 6, 6, 0.7);
  --color-darkest: rgba(0, 0, 0, 0.3);

  --color-black: #000000;
  --color-blacker: rgba(15, 15, 17, 0);
  --color-blackest: #0f0f11;
  --color-border: hsla(0, 0%, 100%, 0.1);


  --color-lessdark: #1e1e1e;




  // Gery Colors
  --color-gray: #65676B;
  // --color-midgray: #9F9F9F;
  --color-midgray: #878787;
  --color-light: #E4E6EA;
  --color-lighter: #CED0D4;
  --color-lightest: #F0F2F5;

  --color-white: #ffffff;



  // Notify Colors
  --color-success: #3EB75E;
  --color-danger: #FF0003;
  --color-warning: #FF8F3C;
  --color-info: #1BA2DB;


  //Social icon colors
  --color-facebook: #3B5997;
  --color-twitter: #1BA1F2;
  --color-youtube: #ED4141;
  --color-linkedin: #0077B5;
  --color-pinterest: #E60022;
  --color-instagram: #C231A1;
  --color-vimeo: #00ADEF;
  --color-twitch: #6441A3;
  --color-discord: #7289da;


  // Extra Color
  --color-extra01: #666666;
  --color-extra02: #606770;
  --color-extra03: #FBFBFD;
  --color-extra04: #1A1A1A;
  --color-extra05: #242424;

  //Border-radius
  --border-width: 2px;
  --radius-small: 6px;
  --radius: 10px;
  --radius-big: 16px;



  //Font weight
  //primary font
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;


  //Shadows
  --shadow-primary: 0 0 20px 5px rgba(251, 83, 67, 0.20);
  --shadow-light: 1px 1px 6px rgba(0, 0, 0, 0.25);
  --shadow-lighter: 4px 4px 20px 20px rgba(0, 0, 0, 0.01);


  //transition easing
  --transition: 0.3s;
  --transition-transform: transform .65s cubic-bezier(.23, 1, .32, 1);

  //Font Family
  --font-primary: 'Inter', sans-serif;
  --secondary-font: 'Inter', sans-serif;
  --font-awesome: 'Font Awesome 5 Pro';



  //Fonts Size
  --font-size-b1: 18px;
  --font-size-b2: 16px;
  --font-size-b3: 14px;
  --font-size-b4: 12px;

  //Line Height
  --line-height-b1: 1.67; //28px
  --line-height-b2: 1.7; //24px
  --line-height-b3: 1.6; //22px
  --line-height-b4: 1.3; //16px

  // Heading Font
  --h1: 56px; //52px 1.29
  --h2: 44px; //44px 1.23
  --h3: 36px; // 34px 1.14
  --h4: 24px; //30px  1.25
  --h5: 18px; //22px 1.24
  --h6: 16px; //20px 1.25
}

// Layouts Variation
$smlg-device: 'only screen and (max-width: 1199px)';
$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';
$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout: 'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout: 'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
$small-mobile: 'only screen and (max-width: 479px)';
