/*--------------------------------
    Single Demo  
-------------------------------*/
@keyframes spin {
    0% { transform: rotate(120deg); }
    100% { transform: rotate(0deg); }
}
@keyframes drop {
    0% {transform: translateY(-200px) scaleY(0.9); opacity: 0;}
    5% {opacity: .7;}
    50% {transform: translateY(0px) scaleY(1); opacity: 1;}
    65% {transform: translateY(-17px) scaleY(.9); opacity: 1;}
    75% {transform: translateY(-22px) scaleY(.9); opacity: 1;}
    100% {transform: translateY(0px) scaleY(1); opacity: 1;}
  }
.single-demo {
    text-align: center;
    background: var(--color-lessdark);
    border-radius: 10px;
    padding: 20px;
    transition: 0.3s;
    position: relative;
    a {
        display: block;
    }

    .thumbnail {
        position: relative;
        img {
            border-radius: 10px;
            width: 100%;
        }
        .image-light {
            display: none;
        }
        .label-badge {
            position: absolute;
            right: 20px;
            border-radius: 5px;
            background-color: transparent;
            background: #fff700;
            padding: 0 12px;
            color: #000;
            font-size: 14px;
            height: 29px;
            line-height: 29px;
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-weight: 500;
            display: inline-block;
            width: 60px;
            bottom: 20px;
        }
    }
    .title {
        margin: 0;
        margin-top: 20px;
        font-size: 20px;
        letter-spacing: 0.5px;
        position: relative;
        display: inline-block;
        
    }
    &:hover {
        transform: translateY(-5px);
    }
}
.react-image {
    img {
        max-height: 300px;
        animation: drop 1s ease-in-out ;
    }
}